


















































import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICategory, ICreateCategory, IUpdateCategory } from '@/interfaces/categories';
import {
  dispatchExpireDataByModuleName,
  dispatchGetBiomarkersForList,
  dispatchGetCategoryById,
  dispatchUpdateCategory,
} from '@/store/crud/actions';
import { crudModules } from '@/constants/globalConstants';
import { IBiomarker } from '@/interfaces/biomarkers';

@Component({})
export default class CategoryForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public categoryId: string;

  public valid = false;
  public name: string = '';
  public selectedBiomarkers: string[] = [];
  public description: string = '';

  public category: ICategory | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;

  public async initiView() {
    if (!this.isCreation && this.categoryId && this.categoryId.length > 0) {
      this.category = await dispatchGetCategoryById(this.$store, this.categoryId);
      if (!this.category || Object.keys(this.category).length === 0) {
        this.forceCreation = true; // force creation if item is not found or error with the API
      }
    }
    this.fetchData();
    this.reset();
  }

  public async fetchData() {
    this.setAppLoading(true);
    await dispatchGetBiomarkersForList(this.$store); // used to display the list of biomarkers
    this.setAppLoading(false);
  }

  /**
   * Resets the form.
   * if the element id is provided, this will be an edit form, so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.selectedBiomarkers = [];
      this.description = '';
    } else {
      if (this.category) {
        this.name = this.category.categoryName || '';
        this.selectedBiomarkers = this.category
          && this.category.biomarkers && this.category.biomarkers.length > 0
          ? this.getBiomarkerIdsFromBiomarkers(this.category.biomarkers as IBiomarker[]) : [];
        this.description = this.category.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('category:cancelled');
  }

  public get isCreation() {
    return this.categoryId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('category:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('category:reset', this.busId);
  }



  /**
   * Updates the element
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * <element>:updated event to notify parent components that a new item has been
   * created
   */
  public async updateCategory(silent = false) {
    const uploadPayload: IUpdateCategory = {};
    if (this.category && this.category.id) {
      this.setFieldIfExistAndValueChanged(uploadPayload, 'categoryName', this.name, this.category.categoryName);

      const originalBiomarersIds = this.getBiomarkerIdsFromBiomarkers(this.category.biomarkers as IBiomarker []);

      this.setFieldIfChanges(uploadPayload, 'biomarkers', this.selectedBiomarkers, originalBiomarersIds);
      this.setFieldIfChanges(uploadPayload, 'description', this.description, this.category.description);

      if (Object.keys(uploadPayload).length > 0) {
        const result = await dispatchUpdateCategory(
          this.$store, { categoryId: this.category.id, category: uploadPayload });
        if (result) {
          if (!silent) {
            this.$emit('category:updated', result);
          }

          // if biomarker was updated with new category, the app needs to expire the biomarker data
          // to force a new fetch
          if (this.objectChanged(originalBiomarersIds, this.selectedBiomarkers)) {
            dispatchExpireDataByModuleName(this.$store, crudModules.BIOMARKERS);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating category', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoCategory: ICategory = {
          categoryName: this.name,
          biomarkers: this.selectedBiomarkers,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoCategory.categoryName)) {
          this.toast('Name is required', true); // name is required
          return;
        }
        if (!this.hasValue(protoCategory.description)) { delete protoCategory.description; }
        const createCategory: ICreateCategory = protoCategory as ICreateCategory;
        this.$emit('category:created', createCategory);
      } else {
        this.updateCategory();
      }
    }
  }
}
