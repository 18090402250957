




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateCategory, dispatchExpireDataByModuleName } from '@/store/crud/actions';
import CategoryForm from '@/components/forms/CategoryForm.vue';
import { ICreateCategory } from '@/interfaces/categories';
import { crudModules } from '@/constants/globalConstants';


@Component({ components: { CategoryForm } })
export default class Category extends AppComponent {
  @Prop({ type: String, required: false }) public categoryId: string;

  public categoryUpdated() {
    this.$router.push('/main/content/categories');
  }

  public async categoryCreated(categoryPayload: ICreateCategory) {
    if (categoryPayload && Object.keys(categoryPayload).length > 0) {
      const result = await dispatchCreateCategory(this.$store, categoryPayload);
      if (result) {
          this.$router.push('/main/content/categories');
          // if the created category includes a biomarker, the app need to refresh the biomarker list
          // by forcing the biomarker list to expire and be reloaded
          if (categoryPayload.biomarkers && categoryPayload.biomarkers.length > 0) {
            dispatchExpireDataByModuleName(this.$store, crudModules.BIOMARKERS);
          }
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/categories');
  }
}
